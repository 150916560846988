/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 24번째 로그에서는 맥북, 스택 오버플로우, 토스 카드에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://premier-village-danang.com/"
  }, "Hotel Danang | Luxury Hotels in Danang | Beach Hotel Danang")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=vOJjAho26JA"
  }, "Fix Thermal Throttling on Your Mac?! - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.earlyadopter.co.kr/122690"
  }, "MWC 2019 - 보조배터리를 품은 에너자이저 스마트폰 - 얼리어답터")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloter.net/archives/333696"
  }, "애플, 새 아이패드 에어·미니 깜짝 발표 이유는?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.coupang.com/np/products/brand-shop?brandName=Apple&filterType=rocket&channel=srp_top_banner"
  }, "쿠팡! - 애플 브랜드관")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.joelonsoftware.com/2019/03/28/the-next-ceo-of-stack-overflow/"
  }, "The next CEO of Stack Overflow – Joel on Software")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.joelonsoftware.com/"
  }, "Joel on Software")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stackoverflow.com/enterprise"
  }, "Stack Overflow for Enterprise")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stackoverflow.com/jobs"
  }, "Developer Jobs, Programming Jobs & More - Stack Overflow")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2017/02/21/how-atlassian-bought-trello/"
  }, "From lunch to acquisition: How Atlassian bought Trello | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.toss.im/2019/04/04/newsroom/press-release/toss-card/"
  }, "토스(toss), 온·오프라인 결제 지원하는 ‘토스카드’ 출시")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
